import { FC, ReactNode, useRef, useState, useCallback } from "react";
import ResizeObserver from "resize-observer-polyfill";
import { motion, useViewportScroll, useTransform, useSpring } from "framer-motion";
import styles from "./horizontalScroll.module.scss";
import useIsomorphicLayoutEffect from "../../hooks/useIsomorphicLayoutEffect";
import NavHeader from "../header";

type Props = {
	children?: ReactNode;
};

const HorizontalScroll: FC = ({ children }: Props) => {

	const scrollRef = useRef<HTMLDivElement>(null);
	const ghostRef = useRef<HTMLDivElement>(null);
	const [scrollRange, setScrollRange] = useState(0);
	const [viewportW, setViewportW] = useState(0);

	useIsomorphicLayoutEffect(() => {
		if (scrollRef.current !== null) {
			scrollRef && setScrollRange(scrollRef.current.scrollWidth);
		}
	}, [scrollRef]);

	const onResize = useCallback((entries) => {
		for (let entry of entries) {
			setViewportW(entry.contentRect.width);
		}
	}, []);

	useIsomorphicLayoutEffect(() => {
		const resizeObserver = new ResizeObserver((entries) => onResize(entries));
		if (ghostRef.current !== null) {
			resizeObserver.observe(ghostRef.current);
		}
		return () => resizeObserver.disconnect();
	}, [onResize]);

	const { scrollYProgress } = useViewportScroll();
	const transform = useTransform(scrollYProgress, [0, 1], [0, -scrollRange + viewportW]);
	const physics = { damping: 15, mass: 0.27, stiffness: 55 };
	const spring = useSpring(transform, physics);

	return (
		<>
			<div className={styles["scroll-container"]} >
				<NavHeader />
				<motion.section
					ref={scrollRef}
					style={{ x: spring }}
					className={styles["children-container"]}
				>
					{children}
					<div className={styles["children"]}>
						<div className={styles["child"]} />
						<div className={styles["child"]} />
						<div className={styles["child"]} />
						<div className={styles["child"]} />
						<div className={styles["child"]} />
						<div className={styles["child"]} />
					</div>
				</motion.section>
			</div>
			<div ref={ghostRef} style={{ height: scrollRange }} className="ghost" />
		</>
	);
};

export default HorizontalScroll;
