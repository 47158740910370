import React from "react";
import styles from "./home.module.scss";
import Image from "next/image";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import Img from "../../assets/png/inawo-home.png";

const HomeLanding = () => {
	// const { scrollYProgress } = useViewportScroll();
	// const transform = useTransform(scrollYProgress, [0, 1], [0, 1]);

	return (
		<div className={styles["container"]}>
			<motion.div
				initial={{ scale: 1 }}
				animate={{
					scale: 1.1,
					transition: {
						delay: 0.3,
						duration: 0.7,
						ease: [0.79, 0.14, -0.15, 0.86],
					},
				}}
				className={styles["container__bg"]}
			>
				<Image
					src={Img}
					priority
					objectFit="cover"
					objectPosition={"top"}
					layout="fill"
					placeholder="blur"
					quality={80}
					alt="Wedding"
				/>
			</motion.div>
			<div className={styles["container__layer"]}></div>
		</div>
	);
};

export default HomeLanding;
