import styles from "./logo.module.scss"

const Logo: React.FC<any> = (props) => {
	return (
		<div {...props} className={styles["container"]}>
			<svg
				id="e6pPnNAsrUC1"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 375 109"
				shapeRendering="geometricPrecision"
				textRendering="geometricPrecision"

			>
				<g transform="translate(-.057831-1.242264)" clipPath="url(#e6pPnNAsrUC11)">
					<g transform="translate(0 0.000122)">
						<path
							d="M2.84819,19.3153c.80888.8782,1.77682,1.5819,2.84824,2.0706c1.14449.5037,2.37426.7652,3.61728.7691c1.25099.007,2.49009-.2517,3.64109-.7604s2.1898-1.2565,3.0523-2.1976c.91-.88,1.6333-1.9475,2.1237-3.1341s.7372-2.4666.7245-3.7579c.0087-1.2863-.2399-2.56049-.7302-3.74179s-1.2116-2.24394-2.118-3.1206c-.8625-.94104-1.9012-1.6889-3.0523-2.19759s-2.3901-.76744-3.64109-.76035c-1.25468.08278-2.48251.41411-3.61728.97612C3.47291,4.46755,1.69416,6.30425,0.712006,8.6076c-.48428,1.20854-.726554,2.5068-.712061,3.8157-.020196,1.3095.222302,2.609.712061,3.8158.508944,1.1621,1.235044,2.2078,2.136184,3.0762v0Z"
							fill="#ffffff"
						/>
						<path
							d="M18.8269,34.1642h-18.656002v72.4698h18.656002v-72.4698Z"
							fill="#ffffff"
						/>
						<path
							d="M95.7865,40.2871c-2.2002-2.7419-5.0041-4.8929-8.1745-6.2708-3.6605-1.5441-7.5852-2.2988-11.5354-2.2185-4.4151-.0797-8.777,1.0096-12.6747,3.165-3.6183,2.043-6.6615,5.0301-8.8295,8.6668h-1.1393v-9.4654h-17.5167v72.4698h18.656v-35.4956c-.0208-2.8274.3338-5.6442,1.0538-8.371.6412-2.4452,1.7026-4.75,3.1331-6.8032c1.341-1.8672,3.0377-3.4278,4.9844-4.5848c2.0833-1.1558,4.4175-1.7364,6.7789-1.6861c1.7877-.1318,3.5819.1353,5.262.7832c1.6802.648,3.2073,1.6618,4.479,2.9734c2.4936,3.2951,3.6943,7.4481,3.3609,11.6247v41.4114h18.6555v-43.7777c.052-4.2814-.504-8.5473-1.651-12.66-.9729-3.5559-2.6191-6.8745-4.8425-9.7612Z"
							fill="#ffffff"
						/>
						<path
							d="M145.004,31.7978c-5.8-.251-11.574.9321-16.848,3.4519s-9.896,6.3043-13.486,11.042l13.501,9.6133c1.913-2.6281,4.389-4.7579,7.231-6.221c2.843-1.4632,5.974-2.2194,9.146-2.2091c3.969-.1374,7.855,1.194,10.966,3.7566c1.401,1.1265,2.529,2.5765,3.295,4.235.767,1.6586,1.15,3.4798,1.12,5.3191v2.2185c-2.366-.9457-4.793-1.7167-7.263-2.3072-3.744-.823-7.567-1.1903-11.393-1.0945-3.858-.0443-7.7.5243-11.393,1.6861-3.281,1.0596-6.343,2.7448-9.029,4.9693-2.423,2.1092-4.384,4.7311-5.753,7.6906-1.457,3.1653-2.188,6.6379-2.137,10.1458-.042,3.4624.638,6.8934,1.994,10.057c1.315,3.0194,3.261,5.6969,5.697,7.8388c2.446,2.214,5.266,3.938,8.317,5.087c3.262,1.257,6.717,1.888,10.196,1.864c4.148.186,8.262-.839,11.877-2.958c3-1.896,5.675-4.295,7.919-7.0991h1.139v7.6901h18.371v-43.422c0-10.3528-2.848-18.1716-8.545-23.4565-5.696-5.2848-14.004-7.9174-24.922-7.8977ZM158.733,83.473c-.772,2.0015-1.936,3.8146-3.418,5.3243-1.523,1.5858-3.333,2.8425-5.326,3.6974-2.161.956-4.488,1.4393-6.836,1.4198-2.895.0493-5.725-.8856-8.061-2.6621-1.099-.7924-1.992-1.8558-2.599-3.0948-.606-1.2389-.907-2.6148-.876-4.0043-.003-1.4544.323-2.8893.951-4.1891.628-1.2999,1.542-2.4283,2.667-3.2945c3.022-2.178,6.669-3.2214,10.339-2.9579c2.563-.0185,5.118.2995,7.605.9465c2.332.6466,4.602,1.518,6.779,2.603-.002,2.1351-.418,4.2479-1.225,6.2117Z"
							fill="#ffffff"
						/>
						<path
							d="M265.77,79.5685h-.997L250.816,34.1642h-19.225L217.777,79.5685h-.997L205.245,34.1642h-20.935l22.359,72.4698h19.795L240.42,60.9335h.997l13.957,45.7005h20.08l22.359-72.4698h-20.65L265.77,79.5685Z"
							fill="#ffffff"
						/>
						<path
							d="M372.209,54.8698c-1.725-4.565-4.334-8.7135-7.666-12.1886-3.331-3.4751-7.314-6.2028-11.702-8.0142-9.58-3.8249-20.185-3.8249-29.765,0-4.387,1.8114-8.371,4.5391-11.702,8.0142-3.332,3.4751-5.941,7.6236-7.666,12.1886-1.913,4.9346-2.88,10.2096-2.848,15.5291-.032,5.3196.935,10.5946,2.848,15.5292c1.731,4.5577,4.343,8.6989,7.674,12.1683c3.331,3.4696,7.311,6.1936,11.694,8.0046c9.573,3.865,20.192,3.865,29.765,0c4.383-1.811,8.363-4.535,11.694-8.0046c3.331-3.4694,5.943-7.6106,7.674-12.1683c1.913-4.9346,2.881-10.2096,2.848-15.5292.033-5.3195-.935-10.5945-2.848-15.5291v0Zm-17.375,24.255c-.907,2.4328-2.283,4.6473-4.044,6.5075-1.68,1.7663-3.698,3.1468-5.924,4.0524-2.186.9382-4.528,1.4206-6.893,1.4198-2.394.0181-4.768-.4648-6.978-1.4198-2.141-.9414-4.078-2.3194-5.697-4.0524-1.769-1.859-3.155-4.0733-4.073-6.5075-1.029-2.7827-1.532-5.7454-1.481-8.7259-.051-2.9804.452-5.9431,1.481-8.7259.918-2.4341,2.304-4.6484,4.073-6.5075c1.625-1.7328,3.56-3.1194,5.697-4.0819c2.216-.9313,4.587-1.4036,6.978-1.3902c2.362.0032,4.701.4751,6.893,1.3902c2.22.9295,4.236,2.3183,5.924,4.0819c1.761,1.8603,3.137,4.0748,4.044,6.5075c2.014,5.6293,2.014,11.8226,0,17.4518Z"
							fill="#ffffff"
						/>
						<g id="e6pPnNAsrUC10_to" transform="translate(337.958488,46.484)">
							<path
								id="e6pPnNAsrUC10"
								d="M331.308,24.6396h12.646L354.607,8.51886c.289-.43478.443-.95062.443-1.47897s-.154-1.04419-.443-1.47897v0l-2.592-4.34817c-.235-.366557-.553-.668414-.925-.87965s-.788-.325541-1.212-.3331h-24.324c-.425.002416-.842.114515-1.215.326211s-.689.516348-.921.886539l-2.677,4.43691c-.289.43478-.444.95062-.444,1.47897s.155,1.04419.444,1.47897l10.567,16.032Z"
								transform="translate(-337.673492,-12.3198)"
								opacity="0"
								fill="#ffffff"
							/>
						</g>
					</g>
					<clipPath id="e6pPnNAsrUC11">
						<rect width="375" height="109" rx="0" ry="0" fill="#fff" />
					</clipPath>
				</g>
			</svg>
      <p>blog</p>
		</div>
	);
};

export default Logo;
