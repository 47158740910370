import { AnimatePresence, motion } from "framer-motion";
import useStore from "../../../store";
import styles from "./menu.module.scss";
import router from "next/router";
import Link from "next/link";

const Menu = () => {
	const menuState = useStore((state) => state.menuState);

	const staggerVariant = {
		enter: {
			transition: {
				staggerChildren: 0.4,
				ease: [0.79, 0.14, 0.15, 0.86],
			},
		},
		exit: {
			transition: {
				staggerChildren: 0.4,
				ease: [0.9, 0.03, 0.69, 0.22],
			},
		},
	};

	const secondaryBackgroundVariant = {
		initial: {
			y: -1800,
			skewY: 4,
		},
		enter: {
			y: 0,
			skewY: 0,
			transition: {
				duration: 1,
			},
		},
		exit: {
			y: -1800,
			transition: {
				delay: 0.4,
				duration: 0.6,
			},
		},
	};

	const backgroundVariant = {
		initial: {
			y: -1800,
			skewY: 4,
		},
		enter: {
			y: 0,
			skewY: 0,
			transition: {
				duration: 1,
			},
		},
		exit: {
			y: -1800,
			transition: {
				delay: 0.4,
				duration: 0.6,
			},
		},
	};

	const categories: { title: string; path: string }[] = [
		{
			title: "Home",
			path: "/",
		},
		{
			title: "Wedding Tips",
			path: "/wedding-tips",
		},
		{
			title: "Wedding diary",
			path: "/wedding-diary",
		},
		{
			title: "Inawo spotlight",
			path: "/spotlight",
		},
	];

	const textVariants = {
		initial: {
			opacity: 0,
			y: 300,
			skewY: 25,
		},
		enter: {
			opacity: 1,
			y: 0,
			skewY: 0,
			transition: {
				delay: 0.65,
				duration: 1.2,
				ease: [0.79, 0.14, -0.15, 0.86],
			},
		},
		exit: {
			opacity: 0,
			y: 300,
			skewY: 0,
			transition: {
				duration: 0.2,
				ease: [0.9, 0.03, 0.69, 0.22],
			},
		},
	};

	const nameVariants = {
		initial: {
			y: 0,
		},
		enter: {
			y: 0,
			transition: {
				delay: 0.8,
				delayChildren: 0.65,
				staggerChildren: 0.2,
				staggerDirection: -1,
				ease: [0.79, 0.14, 0.15, 0.86],
			},
		},
		exit: {
			y: 0,
			transition: {
				duration: 0.2,
				ease: [0.9, 0.03, 0.69, 0.22],
			},
		},
	};

	const letterVariants = {
		initial: {
			y: 300,
		},
		enter: {
			y: 0,
			skewY: 0,
			transition: {
				delay: 0.65,
				duration: 1.2,
				ease: [0.79, 0.14, 0.15, 0.86],
			},
		},
		exit: {
			y: 300,
			transition: {
				duration: 0.5,
				ease: [0.9, 0.03, 0.69, 0.22],
			},
		},
	};

	return (
		<AnimatePresence exitBeforeEnter>
			{menuState.show && (
				<motion.aside
					animate="enter"
					exit="exit"
					variants={staggerVariant}
					className={styles["container"]}
				>
					<motion.div
						initial="initial"
						animate="enter"
						exit="exit"
						variants={backgroundVariant}
						className={styles["container__layer"]}
					>
						<motion.ul animate="enter" exit="exit" variants={staggerVariant}>
							{categories.map((item, idx) => (
								<li key={`menu list ${idx}`}>
									<div className={styles["container__link__hidden-layer"]}>
										<motion.div
											initial="initial"
											animate="enter"
											exit="exit"
											variants={textVariants}
										>
											<Link href={item.path} passHref>
												<motion.a
													className={
														router.pathname === item.path
															? styles["container__link__active"]
															: ""
													}
													variants={nameVariants}
												>
													{Array.from(item.title.split("")).map((i, idx) => (
														<motion.span
															key={`inner menu ${idx}`}
															variants={letterVariants}
														>
															{i}
														</motion.span>
													))}
												</motion.a>
											</Link>
										</motion.div>
									</div>
								</li>
							))}
						</motion.ul>
						<div></div>
					</motion.div>
					<motion.div
						initial="initial"
						animate="enter"
						exit="exit"
						variants={secondaryBackgroundVariant}
						className={styles["container__secondary-colour"]}
					/>
				</motion.aside>
			)}
		</AnimatePresence>
	);
};

export default Menu;
