import HorizontalScroll from "../src/components/horizontal-scroll";
import { NextPage } from "next";
import HomeLanding from "../src/components/home-landing-page";

const Index: NextPage = () => {
	return (
		<>
			<HorizontalScroll>
				<HomeLanding />
			</HorizontalScroll>
		</>
	);
};

export default Index;
