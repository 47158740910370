import * as React from "react";
import useStore from "../../../store";
import Logo from "../animated-logo";
import styles from "./header.module.scss";
import { motion } from "framer-motion";
import Link from "next/link";
import Menu from "../menu";
import useLockedBody from "../../hooks/useLockedBody";

interface NavHeaderProps {}

const NavHeader: React.FC<NavHeaderProps> = () => {
	const setCursorState = useStore((state) => state.setCursorState);
	const menuState = useStore((state) => state.menuState);
	const setMenuState = useStore((state) => state.setMenuState);
	const [locked, setLocked] = useLockedBody(menuState.show);

	return (
		<>
			<header className={styles["header"]}>
				{!menuState.show ? (
					<Link href="/" passHref>
						<Logo
							onMouseEnter={() => setCursorState({ enlarged: true })}
							onMouseLeave={() => setCursorState({ enlarged: false })}
						/>
					</Link>
				) : (
					<>&nbsp;</>
				)}

				<motion.button
					style={{
						width: "40px",
						height: "40px",
						display: "flex",
						placeItems: "center",
						placeContent: "center",
						cursor: "pointer",
					}}
					className={styles["menu-button"]}
					onMouseEnter={() =>
						menuState.show
							? setCursorState({ enlarged: true, inner: "close" })
							: setCursorState({ enlarged: true, inner: "open" })
					}
					onMouseLeave={() => setCursorState({ enlarged: false, inner: "" })}
					onClick={() => {
						setMenuState({ show: !menuState.show });
						setLocked(!locked);
						menuState.show
							? setCursorState({ enlarged: true, inner: "closed" })
							: setCursorState({ enlarged: true, inner: "opened" });
					}}
				>
					<motion.div className={styles["hamburger"]}>
						<div
							className={`
						${styles["lines"]}
						${menuState.show ? styles["open"] : ""}
						`}
						/>
					</motion.div>
				</motion.button>
			</header>
			<Menu />
		</>
	);
};

export default NavHeader;
